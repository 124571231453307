import { FC, useMemo } from 'react'
import { Box, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'shared-redux'
import CategoryWithSubCategories from '#/src/custom/controllers/slideouts/SlideOutCategories/CategoryWithSubCategories'
import { ContentContainer, GrandAccordion, GrandHeading, GrandNextLink, IAccordionItem } from 'ui'
import { usePathname, useRouter } from 'next/navigation'
import {
  selectedMenuCategories,
  setSelectedMenuCategories,
  setSelectedMenuCategoryId
} from 'shared-redux/state'
import { CategoryDTO } from 'ecosystem'

import { MenuItem } from 'ui/storefront'
import { sortCategories } from 'shared-utils'

export interface ISlideOutMobileMenuProps {
  menuItems: MenuItem[]
}

const SlideOutMobileMenu: FC<ISlideOutMobileMenuProps> = ({ menuItems }) => {
  const router = useRouter()
  const pathname = usePathname()
  const menuCategories = sortCategories([...useSelector(selectedMenuCategories)])
  const dispatch = useDispatch()

  const currentStyles = {
    color: 'primary',
    fontWeight: 'bold'
  }
  const accordionItems: IAccordionItem[] = useMemo(() => {
    const handleClick = (item: MenuItem) => {
      dispatch(setSelectedMenuCategoryId(item.id))
      dispatch(setSelectedMenuCategories(item.other as CategoryDTO[]))
    }

    return menuItems
      .filter((e) => e.other?.length)
      .map((item) => ({
        id: item.id,
        title: item.text.toUpperCase(),
        onClick: () => handleClick(item),
        buttonProps: pathname?.includes(item?.path) ? currentStyles : {},
        component: (
          <>
            <Box pb={2}>
              <GrandNextLink aria-label={item.text + '-category'} href={item.path}>
                Se alla
              </GrandNextLink>
            </Box>
            <Wrap flex={1} spacing={4}>
              {menuCategories.map?.((category, index) => (
                <WrapItem key={index + '-navCategoryGroup1-' + category.id}>
                  <CategoryWithSubCategories {...{ category }} />
                </WrapItem>
              ))}
            </Wrap>
          </>
        )
      }))

    // eslint-disable-next-line
  }, [dispatch, router, menuCategories, menuItems])

  return (
    <ContentContainer direction="column" p={0}>
      <VStack align="flex-start" pl="12px">
        <GrandAccordion allowToggle items={accordionItems} w="full" />
        {menuItems
          .filter((e) => !e.other?.length)
          .map((item) => (
            <GrandNextLink aria-label={item.text} href={item.path} key={item.id} px={4} w="full">
              <GrandHeading
                cursor="pointer"
                fontSize={{
                  base: 'sm',
                  md: 'lg'
                }}
                headingTag="h4"
                title={item.text.toUpperCase()}
                {...(pathname === item?.path && currentStyles)}
              />
            </GrandNextLink>
          ))}
      </VStack>
    </ContentContainer>
  )
}

export default SlideOutMobileMenu
