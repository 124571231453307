import { RelatedWidget } from 'ecosystem'
import { useGetCachedRequest } from 'api'

const useStaticPagesVinkallareRequest = () => {
  return useGetCachedRequest<RelatedWidget[]>(
    `/api/widgets/get-static-pages-vinkallare`,
    'staticPages'
  )
}

export default useStaticPagesVinkallareRequest
