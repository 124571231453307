import useStaticPagesVinkallareRequest from './useStaticPagesVinkallareRequest'
import { useMemo } from 'react'

const useVinkallare = () => {
  const { data: staticPages } = useStaticPagesVinkallareRequest()

  const items = useMemo(() => {
    return [
      ...(staticPages
        ? staticPages.map((sp) => ({
            title: sp.name,
            path: sp.slug ? `/${sp.slug}` : ''
          }))
        : [])
    ]
  }, [staticPages])

  return {
    vinkallareItems: items
  }
}

export default useVinkallare
