import { FC } from 'react'
import { Contact, IContactProps } from 'storefront-modules/contact'
import { useModal } from '#/src/custom/controllers'
import { ContactUsFormOverrides } from 'forms'
import { OverridesContextProvider, OverridesContextType } from 'ui/lib/overrides'

const textOverrides: ContactUsFormOverrides = {
  contactUsFormName: 'För- och efternamn',
  contactUsFormNamePlaceholder: 'fyll i ditt för- och efternamn',
  contactUsFormPhonePlaceholder: 'fyll i ditt telefonnummer',
  contactUsFormEmailPlaceholder: 'fyll i din e-postadress',
  contactUsFormProductPlaceholder: 'fyll i produktnamn',
  contactUsFormOrderNumberPlaceholder: 'fyll i ordernummer (frivilligt)',
  contactUsFormDescriptionPlaceholder: 'lägg till en beskrivning av din förfrågan (frivilligt)'
}

export interface CustomContactProps extends Omit<IContactProps, 'apiPath' | 'sectionId'> {}

const CustomContact: FC<CustomContactProps> = ({ submitButtonProps, onSuccess, ...props }) => {
  const { openModal } = useModal()

  const onSuccessCb = () => {
    openModal({
      title: 'Request sent',
      componentId: 'confirmation',
      size: 'md'
    })
  }

  return (
    <OverridesContextProvider overrides={textOverrides as OverridesContextType}>
      <Contact
        apiPath="/api/contact/send-contact"
        onSuccess={onSuccess || onSuccessCb}
        sectionId="contact"
        submitButtonProps={{
          borderRadius: 'md',
          ...submitButtonProps
        }}
        {...props}
      />
    </OverridesContextProvider>
  )
}

export default CustomContact
