import React, { FC } from 'react'
import VinkallareNav from './VinkallareNav'
import { ContentContainer } from 'ui'

export interface ISlideOutVinkallare {}

const SlideOutVinkallare: FC<ISlideOutVinkallare> = () => {
  return (
    <ContentContainer
      borderColor="primary"
      borderTop="1px solid"
      borderTopColor="background.surface"
      minH="20vh"
      py={0}
      w="100%">
      <VinkallareNav />
    </ContentContainer>
  )
}

export default SlideOutVinkallare
