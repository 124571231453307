import React, { FC } from 'react'
import { HStack, StackProps, Wrap, WrapItem } from '@chakra-ui/react'
import useVinkallare from './useVinkallare'
import { MenuItemComponent } from 'ui/storefront'

interface IVinkallareProps extends StackProps {}

const Vinkallare: FC<IVinkallareProps> = ({ ...props }) => {
  const { vinkallareItems } = useVinkallare()

  return (
    <HStack align="flex-start" py={6} spacing={2} w="100%" {...props}>
      <Wrap flex={1} justify="center" spacing={4}>
        {vinkallareItems.map((item) => (
          <WrapItem border="none" key={item.title}>
            <MenuItemComponent id={item.title} {...item} text={item.title} />
          </WrapItem>
        ))}
      </Wrap>
    </HStack>
  )
}

export default Vinkallare
